import PageLogin from '@/pages/PageLogin'
import PageSignup from '@/pages/PageSignup'
import PageAfterSignup from '@/pages/PageAfterSignup'
import PageResetPassword from '@/pages/PageResetPassword'
import PageDashboard from '@/pages/PageDashboard'
import PageMyBots from '@/pages/PageMyBots'
import PageStrategies from '@/pages/PageStrategies'
import PageGaussianChannelStrategy from '@/pages/PageGaussianChannelStrategy'
import PageIchimokuEma4hStrategy from '@/pages/PageIchimokuEma4hStrategy'
import PageEditBot from '@/pages/PageEditBot'
import PageCreateBot from '@/pages/PageCreateBot'
import PageProfile from '@/pages/PageProfile'
import PageHelp from '@/pages/PageHelp'
import PageSubscription from '@/pages/PageSubscription'
import PageNotFound from '@/pages/PageNotFound'
import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: PageLogin
  },
  {
    path: '/login',
    name: 'Login2',
    component: PageLogin
  },
  {
    path: '/signup',
    name: 'Signup',
    component: PageSignup
  },
  {
    path: '/afterSignup',
    name: 'AfterSignup',
    component: PageAfterSignup
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: PageResetPassword
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: PageDashboard
  },
  {
    path: '/bots',
    name: 'MyBots',
    component: PageMyBots
  },
  {
    path: '/strategies',
    name: 'Strategies',
    component: PageStrategies
  },
  {
    path: '/gaussianChannelStrategy',
    name: 'GaussianChannelStrategy',
    component: PageGaussianChannelStrategy
  },
  {
    path: '/ichimokuEma4hStrategy',
    name: 'PageIchimokuEma4hStrategy',
    component: PageIchimokuEma4hStrategy
  },
  {
    path: '/createBot',
    name: 'CreateNewBot',
    component: PageCreateBot
  },
  {
    path: '/bot/:id',
    name: 'EditBot',
    component: PageEditBot,
    props: true
  },
  {
    path: '/me',
    name: 'Profile',
    component: PageProfile
  },
  {
    path: '/help',
    name: 'Help',
    component: PageHelp
  },
  {
    path: '/subscription',
    name: 'Subscription',
    component: PageSubscription
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: PageNotFound
  }
]

export default createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

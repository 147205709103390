<template>

  <TheNavbar />

  <!-- TODO -->
  <div class="mt-4 mb-4">
    <TheSubscriptionAlert />
  </div>

  <div v-if="userBotsEnabled && userBotsDisabled" class="mt-4">
    
    <div class="card mb-4">
      <div class="card-header">
        <div class="row flex-between-center">
          <div class="col-4 col-sm-auto d-flex align-items-center pe-0">
            <h5 class="fs-6 mb-0 text-nowrap py-2 py-xl-0">Active Bots</h5>
          </div>
          <div class="col-8 col-sm-auto ms-auto text-end ps-0">
            <router-link v-if="userBotsEnabled.length > 0" :to="{name: 'CreateNewBot'}" class="btn btn-primary btn-sm" type="button">
              <span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
              Create Bot
            </router-link>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <BotList v-if="userBotsEnabled.length > 0" :bots="userBotsEnabled"/>
        <div v-if="userBotsEnabled.length == 0" class="row justify-content-center mt-5 mb-5">
          <div class="col-auto text-center">

            <!-- <img class="img-fluid rounded img-thumbnail_" style="max-width: 50px" src="@/assets/mybots/arrow_down_animated.gif" /><br> -->
            <span>Create your first Bot<br> and automate your strategies!</span><br>
            <router-link :to="{name: 'CreateNewBot'}" class="mt-3 mb-3" type="button">
              <img class="img-fluid rounded mt-2 mb-2" style="width: 100%;" src="@/assets/mybots/teaser.svg" />
            </router-link>
            <br>
            <router-link :to="{name: 'CreateNewBot'}" class="btn btn-primary fs-7 ps-4 pe-4 pt-2 pb-2 mt-1 mb-1" type="button">
              <span class="fas fa-plus" data-fa-transform="shrink-0 down-0"></span>
              Create Bot
            </router-link>
            <br>
            <img class="img-fluid rounded img-thumbnail_" style="max-width: 50px" src="@/assets/mybots/arrow_up_animated.gif" />

          </div>
        </div>

      </div>
    </div> <!-- card-->

    <div v-if="userBotsDisabled.length > 0" class="card mb-4">
      <div class="card-header">
        <div class="row flex-between-center">
          <div class="col-4 col-sm-auto d-flex align-items-center pe-0">
            <h5 class="fs-6 mb-0 text-nowrap py-2 py-xl-0">Paused Bots</h5>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <BotList :bots="userBotsDisabled"/>
      </div>
    </div> <!-- card-->

    <div v-if="userLogs && userLogs.items.length > 0" class="card mb-4">
      <div class="card-header">
        <div class="row flex-between-center">
          <div class="col-4 col-sm-auto d-flex align-items-center pe-0">
            <h5 class="fs-6 mb-0 text-nowrap py-2 py-xl-0">Logs</h5>
          </div>
          <div class="col-8 col-sm-auto ms-auto text-end ps-0">
            <div v-if="!loadingLogs">
              <a @click="refreshUserLogs" class="btn btn-primary btn-sm" type="button">
                <span class="fas fa-sync-alt" data-fa-transform="shrink-3 down-2"></span>
                Refresh Logs
              </a>
            </div>
            <div v-if="loadingLogs">
              <div class="spinner-border">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div> <!-- header-->
      <div class="card-body bg-body-tertiary p-0">
        <BotLogList :botLogs="userLogs" :bots="allUserBots" />
      </div> <!-- body-->
      <div v-if="userLogs.pages != 1" class="card-footer">
        <div class="d-flex align-items-center justify-content-center">
          <a v-if="!loadingLogs" @click="loadMoreUserLogs()" class="btn btn-secondary btn-sm" type="button">
            <span class="fas fa-sync-alt" data-fa-transform="shrink-3 down-2"></span>
            Load more
          </a>
          <div v-if="loadingLogs">
            <div class="spinner-border">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>

        </div>
      </div> <!-- footer -->
    </div> <!-- card-->

    <div class="card mb-4">
      <div class="card-header">
        <div class="row flex-between-center">
          <div class="col-4 col-sm-auto d-flex align-items-center pe-0">
            <h5 class="fs-6 mb-0 text-nowrap py-2 py-xl-0">Getting Started</h5>
          </div>
        </div>
      </div>
      <div class="card-body p-0_ text-center">
        <p>If you are new here or you want to refresh your memory about how SIGNUM works, watch this video:</p>
        <iframe width="100%" height="450" style="max-width: 800px;" src="https://www.youtube-nocookie.com/embed/KA8_NsVuzgo?si=5juFoDgRr0vq-341" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <p>
          <a href="https://www.youtube.com/@AutoTradingLFG" target="_blank">Watch more tutorials on our YouTube Channel <span class="fas fa-external-link-alt" data-fa-transform="shrink-3 down-0"></span></a>
        </p>
      </div>
    </div> <!-- card-->

    <div class="card mb-4">
      <div class="card-header">
        <div class="row flex-between-center">
          <div class="col-4 col-sm-auto d-flex align-items-center pe-0">
            <h5 class="fs-6 mb-0 text-nowrap py-2 py-xl-0">Get Help</h5>
          </div>
        </div>
      </div>
      <div class="card-body">
        <TheHelpChatbot />
      </div>
    </div> <!-- card-->




  </div>
</template>

<script>
import TheNavbar from '@/components/TheNavbar'
import BotList from '@/components/BotList'
import BotLogList from '@/components/BotLogList'
import TheSubscriptionAlert from '@/components/TheSubscriptionAlert'
import TheHelpChatbot from '@/components/TheHelpChatbot'

export default {
  components: {
    TheNavbar,
    BotList,
    BotLogList,
    TheSubscriptionAlert,
    TheHelpChatbot
  },
  data () {
    return {
      loading: false,
      loadingLogs: false
    }
  },
  computed: {
    allUserBots () {
      return this.$store.getters.getUserBots
    },
    userBotsEnabled () {
      return this.$store.getters.getUserBots?.filter(elem => elem.enabled == true)
    },
    userBotsDisabled () {
      return this.$store.getters.getUserBots?.filter(elem => elem.enabled == false)
    },
    userLogs () {
      return this.$store.getters.getUserLogs
    }
  },
  methods: {
    async refreshUserLogs () {
      this.loadingLogs = true
      
      const userLogsPageLimit = this.$store.getters.getUserLogsPageLimit
      await this.$store.dispatch('getUserLogs', {page: userLogsPageLimit.page, limit: userLogsPageLimit.limit})
      
      this.loadingLogs = false
    },
    async loadMoreUserLogs () {
      this.loadingLogs = true

      const userLogsPageLimit = this.$store.getters.getUserLogsPageLimit
      const newLimit = userLogsPageLimit.limit * 2
      await this.$store.commit('setUserLogsPageLimit', {page: userLogsPageLimit.page, limit: newLimit})
      await this.$store.dispatch('getUserLogs', {page: userLogsPageLimit.page, limit: newLimit})
      
      this.loadingLogs = false
    }
  },
  async created () {
    this.loading = true
    this.loadingLogs = true
    
    if (!this.$store.getters.getUserBots) await this.$store.dispatch('getUserBots')

    const userLogsPageLimit = this.$store.getters.getUserLogsPageLimit
    if (!this.$store.getters.getUserLogs) await this.$store.dispatch('getUserLogs', {page: userLogsPageLimit.page, limit: userLogsPageLimit.limit})
    
    this.loading = false
    this.loadingLogs = false
  },
  beforeMount () {
    // Redirect to Login when the user is not logged in
    if (!this.$store.getters.isUserLoggedIn) this.$router.push({ name: 'Login' })
  }
}
</script>

<style scoped>

</style>
<template>
  <TheNavbar />

  <div class="mt-5 mb-5">

    <div class="row justify-content-center mb-3">
      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">
        <div class="card mb-4">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">Get Help</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary">
            <div>
              <TheHelpChatbot />
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import TheNavbar from '@/components/TheNavbar'
import TheHelpChatbot from '@/components/TheHelpChatbot'

export default {
  components: {
    TheNavbar,
    TheHelpChatbot
  },
  data () {
    return {
    }
  },
  computed: {
    userProfile () {
      return this.$store.getters.getUserProfile
    }
  },
  methods: {
  },
  mounted () {
  },
  beforeMount () {
    // Redirect to Login when the user is not logged in
    if (!this.$store.getters.isUserLoggedIn) this.$router.push({ name: 'Login' })
  }
  
}
</script>

<style scoped>

</style>
<template>

  <TheNavbar />

  <div v-if="bot" class="row justify-content-center mb-3">
    <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6 mt-5">

      <div class="card mb-3">
        <div class="card-header">
          <div class="row flex-between-end">
            <div class="col-auto text-center">
              <h5 class="mb-0" data-anchor="data-anchor">Edit Bot</h5>
            </div>
          </div>
        </div>
        <div class="card-body bg-body-tertiary">
          <p>This Bot runs on your <span class="fw-bold">{{ capitalizeFirstLetter(bot.exchangeId) }}</span> account with the API Key <span class="fw-bold">{{ bot.exchangeConfig?.apiKey }}</span></p>
          <BotEditForm @save="onSave" :bot="bot" :loading="loading" />
        </div>
      </div>

    </div>
  </div>

  <div v-if="bot" class="row justify-content-center">
    <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">
      <div class="alert alert-primary" role="alert">
        <span class="fw-bold">Want to test if your bot works?</span>
        <br>
        We have exactly what you need. A test strategy, which works on the 1 minute timeframe. Watch this video to send test signals quickly: <a href="https://www.youtube.com/watch?v=SnrtZBTQd_8" target="_blank"><span class="fw-bold">YouTube Video</span> <span class="fas fa-external-link-alt" data-fa-transform="shrink-3 down-0"></span></a>
      </div>
    </div>
  </div>

  <div v-if="bot" class="row justify-content-center">
    <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">
      <div class="alert alert-primary" role="alert">
        <span class="fw-bold">Need a trading strategy?</span>
        <br>
        Check our <router-link :to="{name: 'Strategies', params: {}}" class="" href=""><span class="fw-bold">Strategies</span></router-link> page or watch this YouTube video: <a href="https://www.youtube.com/watch?v=C7r9-JVgURc" target="_blank"><span class="fw-bold">Best Bitcoin Trading Strategy</span> <span class="fas fa-external-link-alt" data-fa-transform="shrink-3 down-0"></span></a>
      </div>
    </div>
  </div>

  <div v-if="bot" class="row justify-content-center">
    <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">

      <div class="card mb-3">
        <div class="card-header">
          <div class="row flex-between-end">
            <div class="col-auto text-center">
              <h5>Trigger this Bot</h5>
            </div>
          </div>
        </div>
        <div class="card-body bg-body-tertiary">

          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item"><a class="nav-link active" data-bs-toggle="tab" href="#tab-tv-strategy" role="tab" aria-controls="tab-tv-strategy" aria-selected="true">TradingView Strategy</a></li>
            <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#tab-tv-custom-alerts" role="tab" aria-controls="tab-tv-custom-alerts" aria-selected="false">TradingView Custom Alerts</a></li>
            <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#tab-api" role="tab" aria-controls="tab-api" aria-selected="false">API</a></li>
          </ul>
          <div class="tab-content border-top-0 pt-3">
            <div class="tab-pane fade show active" id="tab-tv-strategy" role="tabpanel" aria-labelledby="video-tab">
              <p>For TradingView you need at least the <span class="fw-bold">Essential</span> plan so you can create Alerts and use Webhooks.</p>
    
              <div class="form-floating mb-3">
                <input :value="bot.webhookDetailsBasic.alertName" @focus="$event.target.select()" class="form-control" type="text" placeholder="Alert Name" readonly>
                <label for="floatingInput">Alert Name</label>
              </div>

              <div class="">                
                <div class="text-start">
                  <label>Position Size</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="positionSize" type="radio" name="radioPositionSize" value="1" />
                  <label class="form-check-label">100%</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="positionSize" type="radio" name="radioPositionSize" value="2" />
                  <label class="form-check-label">Let strategy decide</label>
                </div>
              </div>

              <div class="form-floating mb-3">
                <textarea @focus="$event.target.select()" class="form-control text-start_" style="height: 280px" rows=6 readonly>{{ prettyTvStrategyJsonText(bot) }}</textarea>
                <label for="floatingInput">Alert Message</label>
              </div>

              <div class="form-floating mb-3">
                <input :value="bot.webhookDetailsBasic.url" @focus="$event.target.select()" class="form-control" type="text" placeholder="Alert Name" readonly>
                <label for="floatingInput">Webhook URL</label>
              </div>

              <hr>

              <iframe width="100%" height="420" src="https://www.youtube-nocookie.com/embed/XsQwD1H5NP8?si=GDEMo3y-ejOB46Lj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              <p>
                <span class="fw-bold">Watch this video</span> if you are doing it for the first time.
              </p>

              <hr>
              
              <img class="img-fluid mb-3" src="@/assets/tradingview/setup_alert_1.jpg" />
              <p>
                In TradingView, open the <span class="fw-bold">Strategy Tester</span> and click on the <span class="fw-bold">Alert</span> icon.<br>
                Copy/Paste the title & message from Signum into the TradingView Alert.
              </p>
              
              <img class="img-fluid mb-3" src="@/assets/tradingview/setup_alert_2.jpg" />
              <p>
                Switch to the <span class="fw-bold">Notifications</span> tab, activate the <span class="fw-bold">Webhook URL</span> and copy/paste the URL as shown. Click <span class="fw-bold">Create</span>.
              </p>
              <p>Done :)</p>
            </div> <!-- tab -->
            <div class="tab-pane fade show" id="tab-tv-custom-alerts" role="tabpanel" aria-labelledby="video-tab">
              <p>For TradingView you need at least the <span class="fw-bold">Essential</span> plan so you can create Alerts and use Webhooks.</p>
              
              <div class="form-floating mb-3">
                <input :value="bot.webhookDetailsBasic.alertName" @focus="$event.target.select()" class="form-control" type="text" placeholder="Alert Name" readonly>
                <label for="floatingInput">Alert Name</label>
              </div>

              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="form-floating mb-3">
                    <textarea @focus="$event.target.select()" class="form-control text-start_" style="height: 280px" rows=6 readonly>{{ getPrettyTvCustomAlertJsonText(bot, 'buy') }}</textarea>
                    <label for="floatingInput">Message for Buy Signal</label>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-floating mb-3">
                    <textarea @focus="$event.target.select()" class="form-control text-start_" style="height: 280px" rows=6 readonly>{{ getPrettyTvCustomAlertJsonText(bot, 'sell') }}</textarea>
                    <label for="floatingInput">Message for Sell Signal</label>
                  </div>
                </div>
              </div>

              <div class="form-floating mb-3">
                <input :value="bot.webhookDetailsBasic.url" @focus="$event.target.select()" class="form-control" type="text" placeholder="Alert Name" readonly>
                <label for="floatingInput">Webhook URL</label>
              </div>

              <hr>

              <p>
                In TradingView you can set up Alerts on "almost" anything, also on Trendlines.<br>
                <br>
                In the below example we show you how to setup an <span class="fw-bold">Alert for buying</span> on a Trendline cross.
                <br>
                You can do the exact same for selling by using the <span class="fw-bold">Message for Sell Signal</span> insead (see above).
              </p>
              <img class="img-fluid mb-3" src="@/assets/tradingview/setup_alert_trendline_1.jpg" />
              <p>
                Draw your trendline (horizontally, diagonally or as you desire).
                <br>
                <span class="fw-bold">Right click</span> on it to open up the menu and click on <span class="fw-bold">Add alert</span>.
              </p>
              <img class="img-fluid mb-3" src="@/assets/tradingview/setup_alert_trendline_2.jpg" />
              <p>In the Alert, use the Buy or Sell message depending on your needs. In our example here, it's the buy message.</p>
              <img class="img-fluid mb-3" src="@/assets/tradingview/setup_alert_trendline_3.jpg" />
              <p>
                Paste the <span class="fw-bold">Webhook URL</span> as shown.
                <br>
                Click <span class="fw-bold">Create</span>.
              </p>
              <p>Done :)</p>

            </div> <!-- tab -->
            <div class="tab-pane fade" id="tab-api" role="tabpanel">
              <p>
                Triggering this Bot via API is easy.<br>
                Send a <span class="fw-bold">POST</span> request with the <span class="fw-bold">Buy or Sell JSON</span> to the <span class="fw-bold">Endpoint URL</span> shown below.<br>
                Make sure to add your server's <span class="fw-bold">IP address</span> to the bot's allow list (above), otherwise that signal "shall not pass" 🤪
              </p>

              <div class="row">
                <div class="col-12">
                  <div class="">                
                    <div class="text-start">
                      <label>Examples</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" v-model="apiExamples" type="radio" name="radioApiExamples" value="1" />
                      <label class="form-check-label">Buy 100%, Sell 100%</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" v-model="apiExamples" type="radio" name="radioApiExamples" value="3" />
                      <label class="form-check-label">Buy 25%, Sell 80%</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" v-model="apiExamples" type="radio" name="radioApiExamples" value="4" />
                      <label class="form-check-label">Buy absolute, Sell absolute</label>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-6">
                  <div class="form-floating mb-3">
                    <textarea @focus="$event.target.select()" class="form-control text-start_" style="height: 280px" rows=6 readonly>{{ getPrettyApiJsonText(bot, 'buy') }}</textarea>
                    <label for="floatingInput">POST Body Buy Signal</label>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-floating mb-3">
                    <textarea @focus="$event.target.select()" class="form-control text-start_" style="height: 280px" rows=6 readonly>{{ getPrettyApiJsonText(bot, 'sell') }}</textarea>
                    <label for="floatingInput">POST Body Sell Signal</label>
                  </div>
                </div>
              </div>
              
              <div class="form-floating mb-3">
                <input :value="bot.webhookDetailsBasic.url" @focus="$event.target.select()" class="form-control" type="text" placeholder="Alert Name" readonly>
                <label for="floatingInput">Endpoint URL</label>
              </div>
              
              <hr>

              <p>
                <span class="fw-bold">bot_id</span>*: Identifies your bot. String.<br>
                <br>
                Don't share this with anyone to keep your bots secret.<br>
              </p>  
              
              <hr>
              
              <p>
                <span class="fw-bold">ticker</span>*: Used to protect you from sending a signal to the wrong bot. String.<br>
                <br>
                If the ticker is vastly different (fuzzy match) from the trading pair you configured for your bot above, we will reject the signal.<br>
                <br>
                <span class="fw-bold">Example: "BTC/USD, BTCUSD, BTC/USDC, BTCUSDC, BTC/USDT, BTCUSDT, ..."</span> are all considered the same and will allow the signal to pass. But sending <span class="fw-bold">"BTC/EUR or SOL/USDT"</span> for example, will not pass.
              </p>
              <hr>
              <p>
                <span class="fw-bold">order_size</span>*: Specify how much to buy or sell denominated in the base asset (aka. contracts). String.<br>
                <br>
                For example, when trading BTC/USDT, the <span class="fw-bold">base asset</span> is <span class="fw-bold">BTC</span> and the <span class="fw-bold">quote asset</span> is <span class="fw-bold">USDT</span>.<br>
                <br>  
                That means <span class="fw-bold">order_size</span> is always denominated in the <span class="fw-bold">base asset</span>, for both buy and sell orders.<br>
                This way SIGNUM can automate any strategy you like as TradingView strategies are sending buy & sell order sizes in Contracts (aka. base asset) by default, and SIGNUM supports that.<br>
                <br>
                E.g. Sell 0.1 BTC/USDT means = "Sell 0.1 BTC".<br>
                E.g. Buy 0.1 BTC/USDT means = "Buy 0.1 BTC".<br>
                <br>
                You can use % or absolute numbers for your order_size.<br>
                Remember, the trading pair you are using is defined in your bot above.<br>
                <br>
                <span class="fw-bold">Example: order_size = "69%"</span> means SIGNUM will check your exchange balance and calculate the order_size so that it executes with 69% of your base asset. This method is the best overall because SIGNUM calculates the order_size for you.<br>
                <span class="fw-bold">Example: order_size = "0.042"</span> means SIGNUM will buy/sell 0.042 units (minus exchange fees) of the bot's base asset. If you don't have enough funds, you'll see an error in your logs. This method is advanced and requires you to send the proper amounts (or setup the TradingView strategy properly so it can do so).
              </p>
              <hr>
              
              <p>
                <span class="fw-bold">position_size</span>*: Specify the position size AFTER the trade has been executed. String.<br>
                <br>
                This way SIGNUM knows where you want to end up and will calculate how to get you there:<br>
                <ul>
                  <li><span class="fw-bold">position_size > 0</span> means you want to go into or increase a long position.</li>
                  <li><span class="fw-bold">position_size = 0</span> means you want to go flat (hold no assets).</li>
                  <li><span class="fw-bold">position_size &lt; 0</span> means you want to go into or increase a short position.</li>
                </ul>

                Together with <span class="fw-bold">order_size</span> SIGNUM will calculate the long and short part of your trade and will execute only the long part of it until it supports shorting.<br>
                <br>
                That means:<br>
                <ul>
                  <li>When your strategy is long, SIGNUM will get you long.</li>
                  <li>When your strategy is flat, SIGNUM will get you flat.</li>
                  <li>When your strategy is short, SIGNUM will get you flat (until it supports shorting).</li>
                </ul>

                When using <span class="fw-bold">order_size</span> with <span class="fw-bold">% numbers</span>, <span class="fw-bold">position_size</span> must be set like so:<br>
                <span class="fw-bold">Example: position_size = "1"</span> means that you intend to go/stay long.<br>
                <span class="fw-bold">Example: position_size = "0"</span> means that you intend to go flat.<br>
                <span class="fw-bold">Example: position_size = "-1"</span> means that you intend to go/stay short (SIGNUM will get you flat instead until it supports shorting).<br>
                <br>
                When using <span class="fw-bold">order_size</span> with <span class="fw-bold">absolute numbers</span>, <span class="fw-bold">position_size</span> must be set to the exact amount you want to hold AFTER the trade has been executed. This way SIGNUM can calculate how much to buy or sell to get you to that position size:<br>
                <span class="fw-bold">Example:  position_size = "0.12"</span> means that you intend to hold 0.12 assets after the trade.<br>
                <span class="fw-bold">Example:  position_size = "0"</span> means that you intend to hold 0 assets after the trade aka. you want to be flat.<br>
                <span class="fw-bold">Example:  position_size = "-0.42"</span> means that you intend to go short with 0.42 assets after the trade (SIGNUM will get you flat instead until it supports shorting).
              </p>
              <hr>
              <p>
                <span class="fw-bold">action</span>*: Can either be <span class="fw-bold">"buy"</span> or <span class="fw-bold">"sell"</span>. String.
              </p>
              <hr>
              <p>
                <span class="fw-bold">timestamp</span>*: Used to de-duplicate signals so they don't get executed multiple times. String.<br>
                This can happen when the signal arrives multiple times by mistake or due to an error.
                <br>
                <br>
                Send an ISO timestamp string.<br>
                <br>
                <span class="fw-bold">Example: timestamp = "2024-09-16T10:15:00Z"</span>
              </p>

              <p>* Required</p>

            </div> <!-- tab -->

          </div>
        </div>
      </div>

    </div>
  </div>

  <!-- Call via API -->
  <div v-if="bot && false" class="row justify-content-center">
    <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">

      <div class="card mb-3">
        <div class="card-header">
          <div class="row flex-between-end">
            <div class="col-auto text-center">
              <h5>Call this Bot via API</h5>
            </div>
          </div>
        </div>

        <div class="card-body bg-body-tertiary">

          <p>Send a <span class="fw-bold">POST</span> request to this endpoint with the below payload.</p>

          <div class="form-floating mb-3">
            <input :value="bot.webhookDetailsBasic.url" @focus="$event.target.select()" class="form-control" type="text" placeholder="Alert Name" readonly>
            <label for="floatingInput">API Endpoint</label>
          </div>
          
          <div class="form-floating mb-3">
            <textarea @focus="$event.target.select()" class="form-control text-start_" style="height: 280px" rows=6 readonly>{{ prettyTvStrategyJsonText(bot) }}</textarea>
            <label for="floatingInput">Payload (JSON)</label>
          </div>
        
        </div>
      </div>

    </div>
  </div>

  <div v-if="bot" class="row justify-content-center">
    <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">
      <div class="alert alert-primary" role="alert">
        <span class="fw-bold">Low timeframes?</span>
        <br>
        Please be aware that SIGNUM takes a few seconds to execute your trades. Take care when doing high frequency trading and test it with small amounts first.
      </div>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">

      <div class="card mb-3">
        <div class="card-header">
          <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pe-0">
              <h5 class="fs-6 mb-0 text-nowrap py-2 py-xl-0">Bot Logs</h5>
            </div>
            <div v-if="botLogs" class="col-8 col-sm-auto ms-auto text-end ps-0">
              <div v-if="!loadingLogs">
                <a @click="refreshUserBotLogs" class="btn btn-primary btn-sm" type="button">
                  <span class="fas fa-sync-alt" data-fa-transform="shrink-3 down-2"></span>
                  Refresh Logs
                </a>
              </div>
              <div v-if="loadingLogs">
                <div class="spinner-border">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body bg-body-tertiary p-0">
          <BotLogList :botLogs="botLogs" :bots="allUserBots" />
        </div>

        <div v-if="botLogs.pages != 1 && botLogs.items?.length > 0" class="card-footer">
          <div class="d-flex align-items-center justify-content-center">
            <a v-if="!loadingLogs" @click="loadMoreUserLogs()" class="btn btn-secondary btn-sm" type="button">
              <span class="fas fa-sync-alt" data-fa-transform="shrink-3 down-2"></span>
              Load more
            </a>
            <div v-if="loadingLogs">
              <div class="spinner-border">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>

          </div>
        </div> <!-- footer -->

      </div>

    </div>
  </div>
  
</template>

<script>
import TheNavbar from '@/components/TheNavbar'
import BotEditForm from '@/components/BotEditForm'
import BotLogList from '@/components/BotLogList'
import Tab from 'bootstrap/js/dist/tab.js'

export default {
  components: {
    TheNavbar,
    BotEditForm,
    BotLogList
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      loading: false,
      loadingLogs: false,
      userBotLogsPage: 1,
      userBotLogsLimit: 10,
      positionSize: 1,
      apiExamples: 1
    }
  },
  computed: {
    bot () {
      const bot = this.$store.getters.getUserBots
      if (bot)
        return bot.find(elem => elem.id+"" === this.id)
      
      return null
    },
    botLogs () {
      return this.$store.getters.getUserBotLogs(this.id)
    },
    allUserBots () {
      return this.$store.getters.getUserBots
    },
  },
  methods: {
    async onSave (event) {
      this.loading = true

      await this.$store.dispatch('updateBot', event.newBot)
      this.loading = false

      if (!event.stay) this.$router.push({ name: 'MyBots' })
    },
    async refreshUserBotLogs () {
      this.loadingLogs = true
      await this.$store.dispatch('getUserBotLogs', { botId: this.id, page: this.userBotLogsPage, limit: this.userBotLogsLimit })
      this.loadingLogs = false
    },
    async loadMoreUserLogs () {
      this.loadingLogs = true
      this.userBotLogsLimit = this.userBotLogsLimit * 2
      await this.$store.dispatch('getUserBotLogs', { botId: this.id, page: this.userBotLogsPage, limit: this.userBotLogsLimit })
      this.loadingLogs = false
    },
    getPrettyTvCustomAlertJsonText (bot, action) {
      const jsonText = bot.webhookDetailsBasic.message
      let jsonObj = JSON.parse(jsonText)
      jsonObj.action = action
      if (action == 'buy') jsonObj.position_size = "1"
      if (action == 'sell') jsonObj.position_size = "0"

      return JSON.stringify(jsonObj, undefined, 2)
    },
    getPrettyApiJsonText (bot, action) {
      let jsonText = bot.webhookDetailsAdvanced.message

      let jsonObj = JSON.parse(jsonText)
      jsonObj.ticker = 'BTCUSD'
      
      jsonObj.action = action
      if (action == 'buy') jsonObj.position_size = "1"
      if (action == 'sell') jsonObj.position_size = "0"

      if (this.apiExamples == 1 && action == 'buy') jsonObj.order_size = "100%"
      if (this.apiExamples == 1 && action == 'sell') jsonObj.order_size = "100%"
      
      if (this.apiExamples == 3 && action == 'buy') jsonObj.order_size = "25%"
      if (this.apiExamples == 3 && action == 'sell') {
        jsonObj.order_size = "80%"
        jsonObj.position_size = "1"
      }
      
      if (this.apiExamples == 4 && action == 'buy') {
        jsonObj.order_size = "0.5"
        jsonObj.position_size = jsonObj.order_size
      } 
      if (this.apiExamples == 4 && action == 'sell') {
        jsonObj.order_size = "0.10"
        jsonObj.position_size = "0.4"
      }

      jsonObj.timestamp = new Date().toISOString()
      
      return JSON.stringify(jsonObj, undefined, 2)
    },
    prettyTvStrategyJsonText (bot) {
      let jsonText = (this.positionSize == 1)? bot.webhookDetailsBasic.message : bot.webhookDetailsAdvanced.message
      const jsonObj = JSON.parse(jsonText)

      return JSON.stringify(jsonObj, undefined, 2)
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getSupportUrl () {
      return process.env.VUE_APP_SUPPORT_URL
    },
  },
  async created () {
    // If store data is missing, fetch it
    if (!this.$store.getters.getUserBots) await this.$store.dispatch('getUserBots')
  },
  mounted () {
    console.debug(Tab) // Must use Tab but don't actually need that object as it initializes itself
    this.refreshUserBotLogs()

    if (this.$route.query.cameFrom == 'createBot') {
      this.$confetti.start()
      setTimeout(() => {
        this.$confetti.stop()
      }, "2000");
    }
  },
  beforeMount () {
    // Redirect to Login when the user is not logged in
    if (!this.$store.getters.isUserLoggedIn) this.$router.push({ name: 'Login' })
  }
}
</script>
import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import VueConfetti from 'vue-confetti'
import posthogPlugin from "./plugins/posthog"
import VueImageZoomer from 'vue-image-zoomer'


// Import JS files
import fontawesome from '@/assets/vendors/fontawesome/all.min.js'

// Import CSS files
import '@/assets/theme/css/theme.min.css'

const myApp = createApp(App)

myApp.use(fontawesome)
// myApp.use(bootstrap)

myApp.use(store)
myApp.use(router)
myApp.use(VueConfetti)
myApp.use(posthogPlugin)
myApp.use(VueImageZoomer)
myApp.mount('#app')

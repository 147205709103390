<template>
  <TheNavbar />

  <div class="mt-5">

    <div v-if="loading">
      <div class="row justify-content-center mb-3">
        <div class="col-auto">
          <div class="mb-3 text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div> 

    <div v-if="!loading">
      <div class="row mb-3">
        <div v-if="allUserAssets && allUserAssets?.length > 0" class="col-6 text-start">
          <div class="form-check form-switch">
            <input class="form-check-input" id="flexSwitchCheckChecked" type="checkbox" v-model="showDust" />
            <label class="form-check-label" for="flexSwitchCheckChecked">Show Dust (USD&lt;1)</label>
          </div>
        </div>
        <div class="col-6 col-sm-auto ms-auto text-end ps-0">
          <button @click="refreshAssets" class="btn btn-primary btn-sm" type="button">
            <span class="fas fa-sync-alt" data-fa-transform="shrink-3 down-2"></span>
            Refresh Assets
          </button>
        </div>
      </div>
      
      <div v-if="allUserAssets && allUserAssets?.length <= 0" class="row justify-content-center g-3 mb-3">
        <div class="col text-center">
          No assets found.<br>
          It seems none of your Bots has assets to trade with at the moment.
        </div>
      </div>

      <div v-if="!allUserAssets" class="row justify-content-center g-3 mb-3">
        <div class="col text-center">
          <span class="mb-1">Create your first Bot now</span> &nbsp;
          <router-link :to="{name: 'CreateNewBot'}" class="btn btn-primary btn-sm" type="button">
            <span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
            Create Bot
          </router-link>
        </div>
      </div>


      <!-- Assets Table & Chart -->
      <div v-if="allUserAssets && allUserAssets?.length > 0" class="row g-3 mb-3">

        <div class="col-12 col-xl-6 col-lg-12 col-md-12">

          <div v-if="totalAssetsValueUSD" class="card mb-3">
            <!-- <div class="bg-holder bg-card" style="background-image:url(/backgrounds/corner-2.png)"></div> -->
            <div class="card-header pb-0">
              <h6 class="mb-0 mt-2 d-flex align-items-center fw-bold">Total Asset Value</h6>
            </div>
            <div class="card-body d-flex flex-column justify-content-end">
              <div class="row">
                <div class="col-auto">
                  <p class="font-sans-serif lh-1 mb-1 fs-5">USD {{ getPrettyFloatNumber(totalAssetsValueUSD, 2) }}</p>
                </div>
              </div>
            </div>
          </div>

          <!-- Assets in % -->
          <div class="card mb-3">
            <div class="card-header">
              <h5 class="fs-6 mb-0 text-nowrap py-2 py-xl-0">Assets in %</h5>
            </div>
            <div class="card-body">
              <div>
                <Pie :data="assetsInPercentPieChartData" :options="pieChartOptions" />
              </div>
              <p class="mt-2 mb-0 fs-11 text-center">Based on current USD asset prices. Rounded to nearest number.</p>
            </div>
          </div>

          <!-- Allocated assets in % -->
          <div class="card mb-3">
            <div class="card-header">
              <h5 class="fs-6 mb-0 text-nowrap py-2 py-xl-0">Asset Allocation in %</h5>
            </div>
            <div class="card-body">
              <div>
                <Pie :data="allUserMergedAssetsPieChartData" :options="pieChartOptions" />
              </div>
              <p class="mt-2 mb-0 fs-11 text-center">
                Bot trading pairs are comprised of "BASE COIN/QUOTE COIN" (e.g. BTC/USDT). This chart shows how much you have allocated to the BASE COIN so you know how your portfolio is allocated before you own those coins (e.g 100 USDT on BTC/USDT counts towards BTC). Helps to build a risk adjusted portfolio.
                Based on current USD asset prices. Rounded to nearest number.
              </p>
            </div>
          </div>

          <!-- Exchanges in % -->
          <div class="card">
            <div class="card-header">
              <h5 class="fs-6 mb-0 text-nowrap py-2 py-xl-0">Exchanges in %</h5>
            </div>
            <div class="card-body">
              <div>
                <Pie :data="exchangeUsdInPercentPieChartData" :options="pieChartOptions" />
              </div>
              <p class="mt-2 mb-0 fs-11 text-center">Based on current USD asset prices. Rounded to nearest number.</p>
            </div>
          </div>

        </div>
        <div class="col-12 col-xl-6 col-lg-12 col-md-12">

          <!-- Assets in USD -->
          <div class="card mb-3">
            <div class="card-header">
              <h5 class="fs-6 mb-0 text-nowrap py-2 py-xl-0">Assets in USD</h5>
            </div>
            <div class="card-body p-0">
              
              <div class="table-responsive scrollbar">
                <table class="table table-hover table-sm table-striped fs-10 mb-0 overflow-hidden">
                  <thead class="bg-200">
                    <tr>
                      <th class="text-900 pe-1 align-middle white-space-nowrap">Asset</th>
                      <th class="text-900 pe-1 align-middle white-space-nowrap">USD Value</th>
                      <th class="text-900 pe-1 align-middle white-space-nowrap">Balance</th>
                    </tr>
                  </thead>
                  <tbody class="list" id="table-orders-body">
                    <tr v-for="asset in allUserAssets" :key="asset.coin" class="">
                      <td class="pe-2 py-2 align-middle white-space-nowrap">
                        <span class="fw-bold">{{ asset.coin }}</span>
                      </td>
                      <td class="py-2 align-middle white-space-nowrap">
                        {{ getPrettyFloatNumber(asset.balanceUSD, 2) }}
                      </td>
                      <td class="pe-1 py-2 align-middle white-space-nowrap">
                        {{ getPrettyFloatNumber(asset.balance, 4) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>

          <!-- Bot Assets in USD -->
          <div class="card">
            <div class="card-header">
              <h5 class="fs-6 mb-0 text-nowrap py-2 py-xl-0">Bot Assets in USD</h5>
            </div>
            <div class="card-body p-0">
              
              <div class="table-responsive scrollbar">
                <table class="table table-hover table-sm table-striped fs-10 mb-0 overflow-hidden">
                  <thead class="bg-200">
                    <tr>
                      <th class="text-900 pe-1 align-middle white-space-nowrap">Bot Name</th>
                      <th class="text-900 pe-1 align-middle white-space-nowrap">Asset</th>
                      <th class="text-900 pe-1 align-middle white-space-nowrap">USD Value</th>
                      <th class="text-900 pe-1 align-middle white-space-nowrap">Balance</th>
                      <th class="text-900 pe-1 align-middle white-space-nowrap">Bot Status</th>
                    </tr>
                  </thead>
                  <tbody class="list" id="table-orders-body">
                    <tr v-for="botAsset in allUserBotAssets" :key="botAsset.botId+botAsset.coin">
                      <td class="pe-2 py-2 align-middle white-space-nowrap">
                        <router-link :to="{name: 'EditBot', params: {id: botAsset.botId}}">
                          <span :class="'fw-bold '+getBotAssetLineClassNames(botAsset)">{{ botAsset.botTitle }}</span>
                        </router-link>
                      </td>
                      <td class="pe-2 py-2 align-middle white-space-nowrap">
                        <span :class="'fw-bold '+getBotAssetLineClassNames(botAsset)">{{ botAsset.coin }}</span>
                      </td>
                      <td class="py-2 align-middle white-space-nowrap">
                        <span :class="' '+getBotAssetLineClassNames(botAsset)">{{ getPrettyFloatNumber(botAsset.balanceUSD, 2) }}</span>
                      </td>
                      <td class="py-2 align-middle white-space-nowrap">
                        <span :class="' '+getBotAssetLineClassNames(botAsset)">{{ getPrettyFloatNumber(botAsset.balance, 4) }}</span>
                      </td>
                      <td class="pe-1 py-2 align-middle white-space-nowrap">
                        <span :class="' '+getBotAssetLineClassNames(botAsset)">{{ (getBotById(botAsset.botId).enabled)? 'Active' : 'Paused' }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>

        </div>
      </div> <!-- row -->

    </div> <!-- !loading -->

  </div>

</template>

<script>
import TheNavbar from '@/components/TheNavbar'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Colors } from 'chart.js' // https://www.chartjs.org/docs/latest/api/interfaces/CoreChartOptions.html
import { Pie } from 'vue-chartjs'
ChartJS.register(ArcElement, Tooltip, Legend, Colors)

export default {
  components: {
    TheNavbar,
    Pie
  },
  data () {
    return {
      loading: false,
      showDust: false,
      pieChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          colors: {
            enabled: true
          }
        }
      }
    }
  },
  computed: {
    allUserAssets () {
      const allUserAssets = this.$store.getters.getUserAssets
      let newAllUserAssets = []

      for (let index in allUserAssets) {
        const asset = allUserAssets[index]
        const balanceUSD = asset.balanceUSD // Math.round(asset.balanceUSD) is a problem for 0.5 USD which get's rounded to 1 USD. Rounding here does not make sense.

        // Filter the dust
        if (!this.showDust && balanceUSD < 1) continue

        newAllUserAssets.push(asset)
      }

      return newAllUserAssets
    },
    allUserBotAssets () {
      const allUserBotAssets = this.$store.getters.getUserBotAssets
      let newAllUserBotAssets = []

      for (let index in allUserBotAssets) {
        const asset = allUserBotAssets[index]
        const botId = asset.botId
        const balanceUSD = asset.balanceUSD // Math.round(asset.balanceUSD) is a problem for 0.5 USD which get's rounded to 1 USD. Rounding here does not make sense.        

        // Filter the dust
        if (!this.showDust && balanceUSD < 1) continue

        // Add bot title to array so we can sort by it later on
        asset.botTitle = this.getBotById(botId).title

        newAllUserBotAssets.push(asset)
      }

      // Sort data ASC
      newAllUserBotAssets.sort((a,b) => (a.botTitle > b.botTitle) ? 1 : ((b.botTitle > a.botTitle) ? -1 : 0))

      return newAllUserBotAssets
    },
    allUserBotMergedAssets () { // TODO: Not used yet
      const allUserBotAssets = this.$store.getters.getUserBotAssets

      let allUserBotMergedAssets = []

      for (let index in allUserBotAssets) {
        const asset = allUserBotAssets[index] // Object
        const balanceUSD = asset.balanceUSD // Math.round(asset.balanceUSD) is a problem for 0.5 USD which get's rounded to 1 USD. Rounding here does not make sense.

        // Filter the dust
        if (!this.showDust && balanceUSD < 1) continue

        const botId = asset.botId
        const bot = this.getBotById(botId)

        // INFO: How to handle coins which are not part of the trading pair but are also on the account? Because total assets value in USD counts them, so % would be wrong if we don't count them. Will count them and see what happens.

        // Create the array of allocated assets aka. bot.baseCoin
        if (!allUserBotMergedAssets[botId]) {
          allUserBotMergedAssets[botId] = {
            botTitle: bot.title,
            balanceUSD: balanceUSD
          }
        } else {
          allUserBotMergedAssets[botId].balanceUSD += balanceUSD
        }

      }

      // Sort it based on the USD value DESC
      allUserBotMergedAssets.sort((a,b) => (a.balanceUSD < b.balanceUSD) ? 1 : ((b.balanceUSD < a.balanceUSD) ? -1 : 0))

      return allUserBotMergedAssets
    },
    allUserMergedAssets () {
      const allUserBotAssets = this.$store.getters.getUserBotAssets

      let userMergedAssets = []

      for (let index in allUserBotAssets) {
        const asset = allUserBotAssets[index] // Object
        const balanceUSD = asset.balanceUSD // Math.round(asset.balanceUSD) is a problem for 0.5 USD which get's rounded to 1 USD. Rounding here does not make sense.

        // Filter the dust
        if (!this.showDust && balanceUSD < 1) continue

        const botId = asset.botId
        const bot = this.getBotById(botId)

        // INFO: How to handle coins which are not part of the trading pair but are also on the account? Because total assets value in USD counts them, so % would be wrong if we don't count them. Will count them and see what happens.

        // Create the array of allocated assets aka. bot.baseCoin
        if (!userMergedAssets[bot.baseCoin]) {
          userMergedAssets[bot.baseCoin] = {
            title: bot.baseCoin,
            balanceUSD: balanceUSD
          }
        } else {
          userMergedAssets[bot.baseCoin].balanceUSD += balanceUSD
        }
      }

      // Convert to nummeric array so it can be sorted later on
      userMergedAssets = Object
        .entries(userMergedAssets)
        .map(([i, o]) => ({ i, ...o }));

      // Sort it based on the USD value DESC
      userMergedAssets.sort((a,b) => (a.balanceUSD < b.balanceUSD) ? 1 : ((b.balanceUSD < a.balanceUSD) ? -1 : 0))

      return userMergedAssets
    },
    userUsdPerExchange () {
      return this.$store.getters.getUserUsdPerExchange
    },
    totalAssetsValueUSD () {
      if (!this.allUserAssets) return 0

      let total = 0

      for (let index in this.allUserAssets) {
        const asset = this.allUserAssets[index]
        if (asset.balanceUSD) total += asset.balanceUSD // Fix the NaN, which breaks the whole dashboard otherwise
      }
      
      return total
    },
    assetsInPercentPieChartData () {
      // Init data strcuture otherwise ChartJs crashes
      let data = {
        labels: ['Loading'],
        datasets: [
          {
            data: [100]
          }
        ]
      }

      if (!this.allUserAssets) return data

      // Reset values
      data.labels = []
      data.datasets[0].data = []
      const totalAssetsValueUSD = this.totalAssetsValueUSD

      // Now fill the values in
      for (let index in this.allUserAssets) {
        const asset = this.allUserAssets[index]
        const balancePercent = Math.round(asset.balanceUSD/totalAssetsValueUSD * 100)
        // let balanceUSD = this.getPrettyFloatNumber(asset.balanceUSD, 2)
        // balanceUSD = balanceUSD.replace(",", "")

        data.labels.push(asset.coin+" ("+balancePercent+"%)" )
        data.datasets[0].data.push(balancePercent)
      }

      return data
    },
    exchangeUsdInPercentPieChartData () {
      // Init data strcuture otherwise ChartJs crashes
      let data = {
        labels: ['Loading'],
        datasets: [
          {
            data: [100]
          }
        ]
      }
      
      if (!this.userUsdPerExchange) return data

      // Reset values
      data.labels = []
      data.datasets[0].data = []
      const totalAssetsValueUSD = this.totalAssetsValueUSD

      // Now fill the values in
      for (let index in this.userUsdPerExchange) {
        const exchangeUsd = this.userUsdPerExchange[index]

        const balancePercent = Math.round(exchangeUsd.balanceUSD/totalAssetsValueUSD * 100)
        const balanceUSD = this.getPrettyFloatNumber(exchangeUsd.balanceUSD, 2)
        // balanceUSD = balanceUSD.replace(",", "")
        
        const exchangeObj = this.getExchangeById(exchangeUsd.exchangeId)

        data.labels.push(exchangeObj.name+" ("+balancePercent+"%) ("+balanceUSD+" USD)" )
        data.datasets[0].data.push(balancePercent)
      }

      return data
    },
    allUserMergedAssetsPieChartData () {
      // Init data strcuture otherwise ChartJs crashes
      let data = {
        labels: ['Loading'],
        datasets: [
          {
            data: [100]
          }
        ]
      }

      if (!this.allUserMergedAssets) return data

      // Reset values
      data.labels = []
      data.datasets[0].data = []
      const totalAssetsValueUSD = this.totalAssetsValueUSD

      // Now fill the values in
      for (let index in this.allUserMergedAssets) {
        const asset = this.allUserMergedAssets[index]
        const balancePercent = Math.round(asset.balanceUSD/totalAssetsValueUSD * 100)
        // let balanceUSD = this.getPrettyFloatNumber(asset.balanceUSD, 2)

        data.labels.push(asset.title+" ("+balancePercent+"%)" ) // ("+balanceUSD+" USD)
        data.datasets[0].data.push(balancePercent)
      }

      return data
    },
  },
  methods: {
    getBotById (id) {
      const bots = this.$store.getters.getUserBots
      return bots.find(elem => elem.id == id)
    },
    getExchangeById (id) {
      const platformExchanges = this.$store.getters.getPlatformExchanges
      return platformExchanges.find(elem => elem.id == id)
    },
    async refreshAssets() {
      this.loading = true
      await this.$store.dispatch('getAllUserAssets')
      this.loading = false
    },
    getPrettyFloatNumber (number, numDecimals) {
      let formattedNumber = parseFloat(number).toLocaleString('en-US', {minimumFractionDigits: numDecimals, maximumFractionDigits: numDecimals})
      return formattedNumber
    },
    getBotAssetLineClassNames (botAsset) {
      const bot = this.getBotById(botAsset.botId)
      let classNames = []
      
      if (!bot.enabled){
        classNames.push('text-warning')
      }

      return classNames.join(' ')
    }
  },
  async created () {
    this.loading = true
    if (!this.$store.getters.getUserAssets) await this.$store.dispatch('getAllUserAssets') // Also loads getAllUserBots fyi
    if (!this.$store.getters.getPlatformExchanges) await this.$store.dispatch('getPlatformExchanges')
    this.loading = false
  },
  beforeMount () {
    // Redirect to Login when the user is not logged in
    if (!this.$store.getters.isUserLoggedIn) this.$router.push({ name: 'Login' })
  }  
}
</script>

<style scoped>

</style>